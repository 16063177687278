import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentCheckbox.figmaUrl.android} codeUrl={checklists.componentCheckbox.codeUrl.android} checklists={checklists.componentCheckbox.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Checkboxes allow users to select one or more items from a set. Checkboxes can turn an option on or off.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "width": 280,
            "height": 570,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/react-native/react-native-checkbox-1.gif",
            "align": "center",
            "resize-mode": "contain",
            "alt": "react native checkbox"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h3>{`Single Checkbox`}</h3>
    <p>{`The basic checkbox component is used for individual checkboxes or basic checkbox needs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Checkbox } from "@legion-crossplatform/ui";

const Checkbox = () => {
  return (
    <Checkbox checked={true} label="Checkbox Label" defaultChecked={false} />
  );
};
`}</code></pre>
    <h3>{`Multiple Checkboxes`}</h3>
    <p>{`Example use for component checkbox with multiple items`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Checkbox } from "@legion-crossplatform/ui";

// Create a checkbox group component
const CheckboxGroup = () => {
  const data = [
    { id: 1, label: "Checkbox 1", value: "Checkbox 1" },
    { id: 2, label: "Checkbox 2", value: "Checkbox 2" },
    { id: 3, label: "Checkbox 3", value: "Checkbox 3" },
    { id: 4, label: "Checkbox 4", value: "Checkbox 4" },
    { id: 5, label: "Checkbox 5", value: "Checkbox 5" },
  ];

  const onCheckedItem = (item) => {
    console.log(item);
  };

  return (
    <>
      {data.map((item) => (
        <Checkbox
          checked={checkedIds.includes(item.id)}
          label={item.label}
          onCheckedChange={() => {
            onCheckedItem(item);
          }}
          key={item.id}
          value={item.value}
        />
      ))}
    </>
  );
};
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`checked`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether or not this checkbox is checked.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`defaultChecked`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set default value for checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Modifier to be applied to the checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text label for this checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size to be applied to the checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnCheckboxSize`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`key`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The key to be applied to the checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onCheckedChange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Called when this checkbox is clicked. If `}<inlineCode parentName="td">{`null`}</inlineCode>{`, then this checkbox will not be interactable, unless something else handles its input events and updates its state.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`((Boolean) -> Unit)?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`required`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set aria-required.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sizeAdjust`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size to be applied to the checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scaleIcon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The scale to be applied to the checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scaleSize`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The scale to be applied to the checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`labeledBy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set aria-labeled-by..`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      